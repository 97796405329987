import styled from "styled-components";
import { useLocation } from "react-router-dom";
import API from "../api/api";

interface PopupInterface {
  isPopup?: Boolean;
  setIsPopup?: any;
  type?: string;
  deleteId?: number;
}
const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: #000000;
  display: flex;
  z-index: 1;
  .modal-box {
    z-index: 1;
    width: 400px;
    height: 200px;
    background: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    .modal-text {
      margin: auto;
      .modal-button {
        margin-top: 20px;
        span {
          transition: 0.3s;
          padding: 3px 10px;
          background: #eee;
          border-radius: 5px;
          &:nth-of-type(2) {
            border: 1px solid #bbb;
            background: #fff;
            margin-left: 10px;
          }
          &:hover {
            background: #ccc;
          }
        }
      }
    }
    p {
      margin: auto;
    }
  }
`;
const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #00000099;
  width: 100vw;
  height: 100vh;
`;

const Modal = ({ isPopup, setIsPopup, type, deleteId }: PopupInterface) => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  return (
    <>
      <ModalStyle>
        <ModalBackground onClick={() => isPopup && setIsPopup(!isPopup)} />
        <div className="modal-box">
          <div className="modal-text">
            {type ? (
              <>
                {type === "delete" && <p>게시물을 삭제 하시겠습니까?</p>}
                {type === "login" && (
                  <p>아이디 또는 비밀번호를 확인해 주세요.</p>
                )}
              </>
            ) : (
              <p>모달 타입 지정이 필요합니다.</p>
            )}
            <div className="modal-button">
              {type === "delete" ? (
                <>
                  <span
                    className="no-select"
                    onClick={() => {
                      setIsPopup(false);
                    }}
                  >
                    취소
                  </span>
                  <span
                    className="no-select"
                    onClick={() => {
                      type === "delete"
                        ? API.BOARD_MANAGE({
                            deleteId: deleteId && deleteId,
                            path: String(path),
                            method: "delete",
                          })
                        : setIsPopup(false);
                    }}
                  >
                    확인
                  </span>
                </>
              ) : (
                <span
                  className="no-select"
                  onClick={() => {
                    setIsPopup(false);
                  }}
                >
                  닫기
                </span>
              )}
            </div>
          </div>
        </div>
      </ModalStyle>
    </>
  );
};

export default Modal;
