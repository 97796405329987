import styled from "styled-components";
interface ButtonInterface {
  children?: React.ReactNode;
  buttonType?: "black" | "grey" | "blue";
  w?: string;
  m?: string;
  cursor?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: "submit";
}

const ButtonStyle = styled.button<ButtonInterface>`
  width: ${(props) => props.w || props.w};
  margin: ${(props) => props.m || props.m};
  cursor: ${(props) => props.cursor || props.cursor};
  transition: 0.3s;
  padding: 5px 10px;
  background: #eee;
  text-align: center;
  display: flex;
  border-radius: 5px;
  p {
    margin: auto;
  }

  &.black {
    background: #000000;
    color: #ffffff;
    &:hover {
      transition: 0.1s;
      background: #222;
    }
    &:active {
      transition: 0.1s;
      background: #111;
    }
  }
  &.grey {
    background: #999999;
    &:hover {
      transition: 0.1s;
      background: #777;
    }
    &:active {
      transition: 0.1s;
      background: #888;
    }
  }
  &.blue {
    background: #002266;
    color: #fff;
    &:hover {
      transition: 0.1s;
      background: #013;
    }
    &:active {
      transition: 0.1s;
      background: #017;
    }
  }

  &:hover {
    transition: 0.1s;
    background: #ccc;
  }
  &:active {
    transition: 0.1s;
    background: #ddd;
  }
`;
const Button = ({
  children,
  buttonType,
  w,
  m,
  cursor,
  onClick,
  type,
}: ButtonInterface) => {
  return (
    <ButtonStyle
      onClick={onClick}
      cursor={cursor}
      w={w}
      m={m}
      className={`${buttonType} no-select`}
    >
      {children ? <p>{children}</p> : buttonType && <div>color</div>}
    </ButtonStyle>
  );
};

export default Button;
