import styled from "styled-components";
import { useLocation } from "react-router-dom";
import HeadNav from "../components/Admin/Header";
import Home from "../components/Admin/Home";
import News from "../components/Admin/News";
import Disclose from "../components/Admin/Disclose";
import Recruit from "../components/Admin/Recruit";
import Building from "../components/Admin/Building";
import Inquiry from "../components/Admin/Inquiry";
import Quote from "../components/Admin/Quote";
import Notice from "../components/Admin/Notice";
import { useCookies } from "react-cookie";
const AdminMainStyle = styled.div`
  .body {
    height: calc(100vh - 50px);
    overflow: overlay;
  }
`;
const AdminMain = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const [cookies, setCookie, removeCookie] = useCookies(["adminLoginToken"]);
  const parts = cookies.adminLoginToken && cookies.adminLoginToken.split(".");
  const data = cookies.adminLoginToken && JSON.parse(atob(parts[1])).data;
  return (
    <AdminMainStyle>
      <HeadNav data={data} cookies={cookies} removeCookie={removeCookie} />
      <div className="body">
        {path === "home" && <Home data={data} />}
        {path === "news" && <News data={data} />}
        {path === "disclose" && <Disclose data={data} />}
        {path === "recruit" && <Recruit data={data} />}
        {path === "building" && <Building data={data} />}
        {path === "inquiry" && <Inquiry data={data} />}
        {path === "quote" && <Quote data={data} />}
        {path === "notice" && <Notice data={data} />}
      </div>
    </AdminMainStyle>
  );
};

export default AdminMain;
