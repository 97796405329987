import styled from "styled-components";
import ListBox from "./ListBox";
import { useEffect, useState } from "react";
import Paging from "./Pagenation";
import WriteButton from "./Write";
import API from "../../api/api";

const testVal = {
  number: "번호",
  title: "제목",
  isActive: "게시 상태",
  type_id: "채용 분류",
  status_id: "채용 상태",
  term: "접수기간",
  date: "작성일",
  delete: "삭제",
};

const RecruitStyle = styled.div`
  text-align: center;
  padding: 0 20px;
  position: relative;
  height: 100%;
  h1 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 50px 0;
  }
`;

const Recruit = ({ data }: any) => {
  const [dataList, setDataList] = useState([]);
  const [pageNum, setPageNum] = useState(Number);
  const [lastPageNum, setLastPageNum] = useState();
  useEffect(() => {
    API.BOARD_REQUEST({
      setDataList: setDataList,
      boardName: "Board_Employment",
      setPageNum: setPageNum,
      setLastPageNum: setLastPageNum,
      pageNum: pageNum,
      data: data,
    });
  }, [pageNum, lastPageNum]);

  return (
    <RecruitStyle>
      <h1>게시물 목록</h1>
      {dataList.length > 0 ? (
        <>
          <ListBox
            liWidth={[50, "max", 100, 100, 150, 200, 100, 50]}
            val={testVal}
            list={dataList}
          />
          {Paging({
            lastPageNum: lastPageNum,
            pageNum: pageNum,
            boardName: "Board_Employment",
            setPageNum: setPageNum,
          })}
          <WriteButton />
        </>
      ) : (
        "글이 없습니다."
      )}
    </RecruitStyle>
  );
};

export default Recruit;
