import styled from "styled-components";
import Button from "../Button";
import CalendarBox from "./Calendar";
import { useEffect, useState } from "react";
import API from "../../api/api";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useCookies } from "react-cookie";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "접속자 수",
    },
  },
};

const HomeStyle = styled.div`
  text-align: center;
  padding: 0 20px;
  position: relative;
  h1 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 50px 0 20px;
  }
`;
const UlStyle = styled.ul`
  width: 100%;
  overflow: auto;
  .ul-inner-box {
    .graph {
      display: flex;
      background-color: #eee;
      article {
        padding: 20px 0;
        width: 100%;
        font-size: 18px;
        h1 {
          padding: unset;
        }
        p {
          font-size: 26px;
          font-weight: bold;
        }
        li {
          & + li {
            border-top: 1px solid #ccc;
          }
          padding: 10px 0;
        }
      }
    }
    .date-search {
      .date-search-box {
        width: fit-content;
        margin: 50px auto;
        display: flex;
        button {
          height: 40px;
          margin-top: auto;
        }
      }
    }
    .main-board {
      min-height: 700px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      article {
        margin: auto;
        min-height: 400px;
        width: calc(100% - 10px);
        padding: 50px 0;
        max-width: 500px;
        box-shadow: 0 0 10px 0 #eee;
        h1 {
          padding-top: 0;
        }
        a + a {
          border-top: 1px dashed #ccc;
          display: block;
        }
        ul {
          li {
            padding: 10px 0;
            transition: 0.3s;
            background: #fff;
            &:hover {
              background: #eee;
            }
          }
        }
      }
    }
  }
  .data-result {
    max-width: 500px;
    margin: 20px auto;
    h1 {
      padding: 20px;
      font-size: 18px;
      border-bottom: #ccc solid 1px;
    }
    p {
      padding: 10px;
      font-size: 26px;
    }
  }
  .data-graph {
    margin: auto;
    margin: 50px auto;
    max-width: 1000px;
    canvas {
      width: 100%;
      aspect-ratio: 3/1;
    }
  }
`;

const Home = ({ data }: { data: any }) => {
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();
  const [date, setDate] = useState<any>({});
  const [dateList, setDateList] = useState<any>();
  const [visitList, setVisitList] = useState<any>();
  const [chartData, setChartData] = useState<any>({
    labels: [" "],
    datasets: [
      {
        label: "접속자 수",
        data: [" "],
        backgroundColor: "#003399",
      },
    ],
  });
  const [cookies, setCookie, removeCookie] = useCookies(["adminLoginToken"]);
  const parts = cookies.adminLoginToken && cookies.adminLoginToken.split(".");
  const coockieData =
    cookies.adminLoginToken && JSON.parse(atob(parts[1])).data;
  useEffect(() => {
    data && API.ADMIN_MAIN_POST({ setDateList, data });
    data && API.COUNT_DAY({ setVisitList, data });
  }, [data]);

  useEffect(() => {
    if (start?.split("T")[0] === end?.split("T")[0]) {
      return setChartData({
        labels:
          date?.index?.length > 0 &&
          date?.index.reverse().map((res: any) => {
            return res.date.split("T")[1].slice(0, 5);
          }),
        datasets: [
          {
            label: "시간별 접속자",
            data:
              date?.index?.length > 0 &&
              date?.index.reverse().map((res: any) => {
                return res.count;
              }),
            backgroundColor: "#003399",
          },
        ],
      });
    } else if (
      start?.split("T")[0].slice(0, 7) === end?.split("T")[0].slice(0, 7)
    ) {
      let sum = 0;
      let sums: number[] = [];
      date?.index?.length > 0 &&
        date?.index.map((res: any, idx: number) => {
          sum += res.count;
          if ((idx + 1) % 24 === 0) {
            sums.unshift(sum);
            sum = 0;
          }
        });

      let monthDates: string[] = [];
      date?.index?.length > 0 &&
        date?.index.map((res: any, idx: number) => {
          if ((idx + 1) % 24 === 0) {
            monthDates.unshift(res.date.split("T")[0].slice(0, 10));
          }
        });
      return setChartData({
        labels: monthDates,
        datasets: [
          {
            label: "일별 접속자",
            data: sums,
            backgroundColor: "#003399",
          },
        ],
      });
    } else if (
      start?.split("T")[0].slice(0, 7) !== end?.split("T")[0].slice(0, 7)
    ) {
      let sum = 0;
      let sums: number[] = [];
      date?.index?.length > 0 &&
        date?.index.map((res: any, idx: number) => {
          if (
            date?.index.length > idx + 1 &&
            date?.index[idx].date.split("-")[1].slice(0, 7) ===
              date?.index[idx + 1].date.split("-")[1].slice(0, 7)
          ) {
            sum += res.count;
          } else {
            sums.unshift(sum);
            sum = 0;
          }
          return sums;
        });

      let monthDates: string[] = [];
      date?.index?.length > 0 &&
        date?.index.map((res: any, idx: number) => {
          if (
            date?.index.length > idx + 1 &&
            date?.index[idx].date.split("-")[1].slice(0, 7) ===
              date?.index[idx + 1].date.split("-")[1].slice(0, 7)
          ) {
          } else {
            monthDates.unshift(res.date.split("T")[0].slice(0, 7));
          }
        });

      return setChartData({
        labels: monthDates,
        datasets: [
          {
            label: "월별 접속자",
            data: sums,
            backgroundColor: "#003399",
          },
        ],
      });
    }
  }, [date]);

  return (
    <HomeStyle>
      <h1>홈페이지 이용자 현황</h1>
      <UlStyle>
        <div className="ul-inner-box">
          <section className="graph">
            <article className="graph-day">
              <ul>
                <li>
                  <h1>일별</h1>
                </li>
                <li>
                  <p>{visitList?.TodayVisitor}</p>
                </li>
              </ul>
            </article>
            <article className="graph-month">
              <ul>
                <li>
                  <h1>실시간</h1>
                </li>
                <li>
                  <p>{visitList?.LiveHourVisitor}</p>
                </li>
              </ul>
            </article>
            <article className="graph-year">
              <ul>
                <li>
                  <h1>누적</h1>
                </li>
                <li>
                  <p>{visitList?.AllVisitor}</p>
                </li>
              </ul>
            </article>
          </section>

          <section className="date-search">
            <span className="date-search-box">
              <CalendarBox setStart={setStart} setEnd={setEnd} />
              <Button
                onClick={() => API.COUNT_SEARCH({ start, end, setDate, data })}
                m="0 0 0 10px"
                cursor="pointer"
                w="52px"
                buttonType="blue"
              >
                조회
              </Button>
            </span>

            <article className="data-result">
              <span>
                <h1>사용자</h1>
                <p>{date ? date?.count : 0}</p>
              </span>
            </article>
            <article className="data-graph">
              <Bar options={options} data={chartData} />
            </article>
          </section>

          <h1>홈페이지 이용자 현황</h1>
          <section className="main-board">
            <article>
              <h1>견적 문의</h1>
              {dateList?.Ouote.length > 0
                ? dateList?.Ouote.map((res: any, idx: number) => {
                    return (
                      <Link to={`/quote/${res.id}`} key={idx}>
                        <ul>
                          <li>{res.title}</li>
                        </ul>
                      </Link>
                    );
                  })
                : "문의가 없습니다."}
            </article>
            <article>
              <h1>1:1 문의</h1>
              {dateList?.Inquiry.length > 0
                ? dateList?.Inquiry.map((res: any, idx: number) => {
                    return (
                      <Link to={`/inquiry/${res.id}`} key={idx}>
                        <ul>
                          <li>{res.title}</li>
                        </ul>
                      </Link>
                    );
                  })
                : "문의가 없습니다."}
            </article>
          </section>
        </div>
      </UlStyle>
    </HomeStyle>
  );
};

export default Home;
