import { useEffect, useState } from "react";
import styled from "styled-components";
import Editor from "./Editor";
import { useLocation } from "react-router-dom";

const ImgAddStyle = styled.div`
  flex-flow: wrap;
  display: flex;
  input {
    display: none;
  }
  .image-add {
    width: 100px;
    aspect-ratio: 1/1;
    border: #666 1px solid;
    display: flex;
    img {
      width: 25px;
      aspect-ratio: 1/1;
      margin: auto;
    }
  }
  .image-box {
    position: relative;
    .image-preview {
      width: 100px;
      aspect-ratio: 1/1;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border: #666 1px solid;
      border-radius: 2px;
    }
    .remove-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 15px;
      height: 15px;
      background: #00000055;
      border-radius: 100em;
      transition: 0.3s;
      &::after,
      &::before {
        content: "";
        width: 10px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 7px;
        right: 2px;
        transform: rotate(45deg);
      }
      &::before {
        transform: rotate(-45deg);
      }
    }
  }
  .close-up {
    position: fixed;
    width: calc(100vw);
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
    left: 0;
    z-index: 20;
    background-color: #00000099;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;
const BoardBoxStyle = styled.div`
  ul {
    li {
      display: flex;
      padding: 10px 0;
      & > div:first-child {
        min-width: 100px;
        width: 100px;
        font-weight: 600;
        height: 100%;
      }
      .title,
      .term {
        margin: auto 0;
      }
      .input_area {
        border: 1px solid #999;
        width: 100%;
        input {
          padding: 5px 0;
          width: inherit;
          display: inline-block;
          transition: 0.3s;
          &:focus-visible {
            transition: 0.1s;
            outline: none;
            box-shadow: 0 0 5px 0 #00000066;
          }
        }
        textarea {
          display: inline-block;
          border: none;
          width: inherit;
          height: 100%;
          padding: 5px;
          transition: 0.3s;
          &:focus-visible {
            transition: 0.1s;
            outline: none;
            box-shadow: 0 0 5px 0 #00000066;
          }
        }
      }
      .input_radio_area {
        span {
          margin-right: 30px;
        }
        & span:last-child {
          margin-right: 0px;
        }
      }
      .main_post_value {
      }
    }
  }
  .contents-css {
    flex-direction: column;
    .contents {
      margin-bottom: 10px;
    }
  }
  .dot_line {
    border-bottom: 2px dotted #999;
  }
`;

const ImagePreview = ({
  bg,
  setImageList,
  imageList,
  setContentsSend,
  contentsSend,
}: any) => {
  const [isImagePopup, setIsImagePopup] = useState(false);
  return (
    <div className="image-box">
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className={`${"image-preview"}`}
        onClick={() => !isImagePopup && setIsImagePopup(!isImagePopup)}
      >
        {isImagePopup && (
          <div
            onClick={() => isImagePopup && setIsImagePopup(!isImagePopup)}
            style={{
              backgroundImage: `url(${bg})`,
            }}
            className={`${"close-up"}`}
          />
        )}
      </div>
      <span
        className="remove-button"
        onClick={() => (
          setImageList(imageList.filter((e: any) => e !== bg)),
          setContentsSend({
            ...contentsSend,
            ["imageList"]: imageList.filter((e: any) => e !== bg),
          })
        )}
      />
    </div>
  );
};

const ImageAdd = ({ setImageList, setContentsSend, contentsSend }: any) => {
  return (
    <>
      <label htmlFor="image">
        <div className="image-add">
          <img src="/icon/picture.svg" alt="add" />
        </div>
      </label>
      <input
        onChange={(e: any) => (
          setImageList([URL.createObjectURL(e.target.files[0])]),
          setContentsSend({
            ...contentsSend,
            [e.target.name]: e.target.files,
          })
        )}
        multiple={false}
        accept="image/jpeg,image/png"
        id="image"
        name="imageList"
        type={"file"}
        placeholder={"1장 까지 가능합니다."}
      />
    </>
  );
};

const Board = ({ listData, contentsSend, setContentsSend }: any) => {
  const [imageList, setImageList] = useState<string[]>([]);
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const path2 = location.pathname.split("/")[2];
  useEffect(() => {
    if (listData.imageList !== undefined) {
      setImageList(listData.imageList);
      setContentsSend({ ...listData });
    } else if (path === "inquiry" || path === "quote" || path2 === "write") {
      // 게시글 작성시 기본 설정
      setContentsSend({
        isActive: true,
        isMainpage: false,
        status_id: listData.status_id,
        type_id: 1,
        isDone: listData.isDone,
      });
    }
  }, [listData]);

  const onChangeData = (e: any) => {
    const { value, name } = e.target;
    e &&
      setContentsSend({
        ...contentsSend,
        [name]: value === "true" ? true : value === "false" ? false : value,
      });
  };
  return (
    <>
      {path === "news" && (
        <BoardBoxStyle>
          <ul>
            <li>
              <div className="title">
                <p>제목</p>
              </div>
              <div className="title_value input_area">
                <input
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                  name="title"
                  type={"text"}
                  defaultValue={listData?.title ? listData.title : ""}
                />
              </div>
            </li>
            <li>
              <div className="post_status">
                <p>게시 상태</p>
              </div>
              <div className="post_status_value input_radio_area">
                {
                  <>
                    <span>
                      게시중
                      <input
                        name="isActive"
                        type={"radio"}
                        value={"true"}
                        onChange={(e) => {
                          onChangeData(e);
                        }}
                        checked={contentsSend.isActive}
                      />
                    </span>
                    <span>
                      게시중지
                      <input
                        name="isActive"
                        type={"radio"}
                        value={"false"}
                        onChange={(e) => {
                          onChangeData(e);
                        }}
                        checked={!contentsSend.isActive}
                      />
                    </span>
                  </>
                }
              </div>
            </li>
            <li>
              <div className="main_post">
                <p>메인 게시</p>
              </div>
              <div className="main_post_value input_radio_area">
                {
                  <>
                    <span>
                      게시중
                      <input
                        name="isMainpage"
                        type={"radio"}
                        value={"true"}
                        onChange={(e) => {
                          onChangeData(e);
                        }}
                        checked={contentsSend.isMainpage}
                      />
                    </span>
                    <span>
                      게시중지
                      <input
                        name="isMainpage"
                        type={"radio"}
                        value={"false"}
                        onChange={(e) => {
                          onChangeData(e);
                        }}
                        checked={!contentsSend.isMainpage}
                      />
                    </span>
                  </>
                }
              </div>
            </li>
            <li>
              <div className="image_area">
                <p>썸네일</p>
              </div>
              <div className="image_area_value">
                <ImgAddStyle>
                  {imageList &&
                    imageList.map((data, idx) => {
                      return (
                        <ImagePreview
                          setImageList={setImageList}
                          imageList={imageList}
                          key={idx}
                          bg={data}
                          setContentsSend={setContentsSend}
                          contentsSend={contentsSend}
                        />
                      );
                    })}
                  {imageList && imageList.length < 1 && (
                    <ImageAdd
                      imageList={imageList}
                      setImageList={setImageList}
                      setContentsSend={setContentsSend}
                      contentsSend={contentsSend}
                    />
                  )}
                </ImgAddStyle>
              </div>
            </li>
            <li className="contents-css">
              <div className="contents">
                <p>내용</p>
              </div>
              <Editor
                contentsSend={contentsSend}
                setContentsSend={setContentsSend}
                listData={listData}
              />
            </li>
          </ul>
        </BoardBoxStyle>
      )}
      {path === "disclose" && (
        <BoardBoxStyle>
          <ul>
            <li>
              <div className="title">
                <p>제목</p>
              </div>
              <div className="title_value input_area">
                <input
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                  name="title"
                  type={"text"}
                  defaultValue={listData.title && listData.title}
                />
              </div>
            </li>
            <li>
              <div className="post_status">
                <p>게시 상태</p>
              </div>
              <div className="post_status_value input_radio_area">
                {
                  <>
                    <span>
                      게시중
                      <input
                        name="isActive"
                        type={"radio"}
                        value={"true"}
                        onChange={(e) => {
                          onChangeData(e);
                        }}
                        checked={contentsSend.isActive}
                      />
                    </span>
                    <span>
                      게시중지
                      <input
                        name="isActive"
                        type={"radio"}
                        value={"false"}
                        onChange={(e) => {
                          onChangeData(e);
                        }}
                        checked={!contentsSend.isActive}
                      />
                    </span>
                  </>
                }
              </div>
            </li>
            <li className="contents-css">
              <div className="contents">
                <p>내용</p>
              </div>
              <Editor
                contentsSend={contentsSend}
                setContentsSend={setContentsSend}
                listData={listData}
              />
            </li>
          </ul>
        </BoardBoxStyle>
      )}
      {path === "recruit" && (
        <BoardBoxStyle>
          <ul>
            <li>
              <div className="title">
                <p>제목</p>
              </div>
              <div className="title_value input_area">
                <input
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                  name={"title"}
                  type={"text"}
                  defaultValue={listData.title && listData.title}
                />
              </div>
            </li>
            <li>
              <div className="term">
                <p>접수기간</p>
              </div>
              <div className="term_value input_area">
                <input
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                  name={"term"}
                  type={"text"}
                  defaultValue={listData.term && listData.term}
                />
              </div>
            </li>
            <li>
              <div className="recruit_status">
                <p>상태</p>
              </div>
              <div className="recruit_status_value input_radio_area">
                <span>
                  진행전
                  <input
                    name="status_id"
                    type={"radio"}
                    value={1}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={
                      Number(contentsSend.status_id) === 1 ? true : false
                    }
                  />
                </span>
                <span>
                  진행중
                  <input
                    name="status_id"
                    type={"radio"}
                    value={Number(2)}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={
                      Number(contentsSend.status_id) === 2 ? true : false
                    }
                  />
                </span>
                <span>
                  채용 완료
                  <input
                    name="status_id"
                    type={"radio"}
                    value={3}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={
                      Number(contentsSend.status_id) === 3 ? true : false
                    }
                  />
                </span>
              </div>
            </li>
            <li>
              <div className="post_status">
                <p>게시 상태</p>
              </div>
              <div className="post_status_value input_radio_area">
                <span>
                  게시중
                  <input
                    name="isActive"
                    type={"radio"}
                    value={"true"}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={contentsSend.isActive}
                  />
                </span>
                <span>
                  게시중지
                  <input
                    name="isActive"
                    type={"radio"}
                    value={"false"}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={!contentsSend.isActive}
                  />
                </span>
              </div>
            </li>
            <li>
              <div className="recruit_divide">
                <p>지원 구분</p>
              </div>
              <div className="recruit_divide_value input_radio_area">
                <span>
                  신입
                  <input
                    name="type_id"
                    type={"radio"}
                    value={1}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={
                      Number(contentsSend.type_id) === 1
                        ? true
                        : contentsSend.type_id === undefined
                        ? true
                        : false
                    }
                  />
                </span>
                <span>
                  경력
                  <input
                    name="type_id"
                    type={"radio"}
                    value={2}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={Number(contentsSend.type_id) === 2 ? true : false}
                  />
                </span>
                <span>
                  신입/경력
                  <input
                    name="type_id"
                    type={"radio"}
                    value={3}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={Number(contentsSend.type_id) === 3 ? true : false}
                  />
                </span>
                <span>
                  인턴
                  <input
                    name="type_id"
                    type={"radio"}
                    value={4}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={Number(contentsSend.type_id) === 4 ? true : false}
                  />
                </span>
              </div>
            </li>
            <li className="contents-css">
              <div className="contents">
                <p>내용</p>
              </div>
              <Editor
                contentsSend={contentsSend}
                setContentsSend={setContentsSend}
                listData={listData}
              />
            </li>
          </ul>
        </BoardBoxStyle>
      )}
      {path === "building" && (
        <BoardBoxStyle>
          <ul>
            <li>
              <div className="title">
                <p>이름</p>
              </div>
              <div className="title_value input_area">
                <input
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                  name={"title"}
                  type={"text"}
                  defaultValue={listData.title && listData.title}
                />
              </div>
            </li>
            <li>
              <div className="adress">
                <p>소재지</p>
              </div>
              <div className="adress_value input_area">
                <input
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                  name={"place"}
                  type={"text"}
                  defaultValue={listData.place && listData.place}
                />
              </div>
            </li>
            <li>
              <div className="space">
                <p>면적</p>
              </div>
              <div className="space_value input_area">
                <input
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                  name={"area"}
                  type={"text"}
                  defaultValue={listData.area && listData.area}
                />
              </div>
            </li>
            <li>
              <div className="description">
                <p>기타</p>
              </div>
              <div className="description_value input_area">
                <input
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                  name={"discription"}
                  type={"text"}
                  defaultValue={listData.discription && listData.discription}
                />
              </div>
            </li>
            <li>
              <div className="post_status">
                <p>게시 상태</p>
              </div>
              <div className="post_status_value input_radio_area">
                {
                  <>
                    <span>
                      게시중
                      <input
                        name="isActive"
                        type={"radio"}
                        value={"true"}
                        onChange={(e) => {
                          onChangeData(e);
                        }}
                        checked={contentsSend.isActive}
                      />
                    </span>
                    <span>
                      게시중지
                      <input
                        name="isActive"
                        type={"radio"}
                        value={"false"}
                        onChange={(e) => {
                          onChangeData(e);
                        }}
                        checked={!contentsSend.isActive}
                      />
                    </span>
                  </>
                }
              </div>
            </li>
            <li>
              <div className="image_area">
                <p>이미지</p>
              </div>
              <div className="image_area_value">
                <ImgAddStyle>
                  {imageList &&
                    imageList.map((data, idx) => {
                      return (
                        <ImagePreview
                          setImageList={setImageList}
                          imageList={imageList}
                          key={idx}
                          bg={data}
                          setContentsSend={setContentsSend}
                          contentsSend={contentsSend}
                        />
                      );
                    })}
                  {imageList && imageList.length < 1 && (
                    <ImageAdd
                      imageList={imageList}
                      setImageList={setImageList}
                      setContentsSend={setContentsSend}
                      contentsSend={contentsSend}
                    />
                  )}
                </ImgAddStyle>
              </div>
            </li>
          </ul>
        </BoardBoxStyle>
      )}
      {/* 1:1 문의 */}
      {path === "inquiry" && (
        <BoardBoxStyle>
          <ul>
            <li>
              <div className="title">
                <p>제목</p>
              </div>
              <div className="title_value">
                <p>{listData.title && listData.title}</p>
              </div>
            </li>
            <li>
              <div className="type">
                <p>종류</p>
              </div>
              <div className="type_value">
                <p>
                  {listData.type_id === 1
                    ? "제보"
                    : listData.type_id === 2
                    ? "건의"
                    : "기타"}
                </p>
              </div>
            </li>
            {/* <li>
              <div className="email">
                <p>이메일</p>
              </div>
              <div className="email_value">
                <p>{listData.email && listData.email}</p>
              </div>
            </li>
            <li>
              <div className="contact">
                <p>연락처</p>
              </div>
              <div className="contact_value">
                <p>{listData.contact && listData.contact}</p>
              </div>
            </li> */}
            <li className="contents-css">
              <div className="contents">
                <p>내용</p>
              </div>
              <div className="contents_value">
                <div
                  dangerouslySetInnerHTML={{
                    __html: listData.content ? listData.content : "",
                  }}
                ></div>
              </div>
            </li>
            <li>
              <div className="quote_status">
                <p>상태</p>
              </div>
              <div className="quote_status_value input_radio_area">
                <span>
                  대기중
                  <input
                    name="status_id"
                    type={"radio"}
                    value={1}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={
                      Number(contentsSend.status_id) === 1 ? true : false
                    }
                  />
                </span>
                <span>
                  처리중
                  <input
                    name="status_id"
                    type={"radio"}
                    value={2}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={
                      Number(contentsSend.status_id) === 2 ? true : false
                    }
                  />
                </span>
                <span>
                  처리완료
                  <input
                    name="status_id"
                    type={"radio"}
                    value={3}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={
                      Number(contentsSend.status_id) === 3 ? true : false
                    }
                  />
                </span>
              </div>
            </li>
            <div className="dot_line" />
            <li>
              <div className="memo">
                <p>메모</p>
              </div>
              <div className="memo_value input_area">
                <textarea
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                  name={"memo"}
                  defaultValue={listData.memo && listData.memo}
                  rows={5}
                />
              </div>
            </li>
          </ul>
        </BoardBoxStyle>
      )}
      {/* 견적 문의 */}
      {path === "quote" && (
        <BoardBoxStyle>
          <ul>
            <li>
              <div className="title">
                <p>제목</p>
              </div>
              <div className="title_value">
                <p>{listData.title && listData.title}</p>
              </div>
            </li>
            <li>
              <div className="company">
                <p>업체명</p>
              </div>
              <div className="company_value">
                <p>{listData.company && listData.company}</p>
              </div>
            </li>
            <li>
              <div className="product">
                <p>제품</p>
              </div>
              <div className="product_value">
                <p>
                  {listData.productType_id &&
                    listData.productType_id === 1 &&
                    "서버"}
                  {listData.productType_id &&
                    listData.productType_id === 2 &&
                    "스토리지"}
                  {listData.productType_id &&
                    listData.productType_id === 3 &&
                    "DPS"}
                  {listData.productType_id &&
                    listData.productType_id === 4 &&
                    "클라이언트 솔루션"}
                  {listData.productType_id &&
                    listData.productType_id === 5 &&
                    "네트워크"}
                  {listData.productType_id &&
                    listData.productType_id === 6 &&
                    "기타"}
                  {listData.productType_id &&
                    listData.productType_id === 8 &&
                    "Omada"}
                  {listData.productType_id &&
                    listData.productType_id === 9 &&
                    "VIGI"}
                  {listData.productType_id &&
                    listData.productType_id === 10 &&
                    "전자칠판"}
                  {listData.productType_id &&
                    listData.productType_id === 11 &&
                    "TAPO"}
                  {listData.productType_id &&
                    listData.productType_id === 12 &&
                    "미설정"}
                  {listData.productType_id &&
                    listData.productType_id === 13 &&
                    "미설정"}
                  {listData.productType_id &&
                    listData.productType_id === 14 &&
                    "미설정"}
                  {listData.productType_id &&
                    listData.productType_id === 15 &&
                    "미설정"}
                  {listData.productType_id &&
                    listData.productType_id === 16 &&
                    "미설정"}
                  {listData.productType_id &&
                    listData.productType_id === 17 &&
                    "서비스"}
                </p>
              </div>
            </li>
            <li>
              <div className="manager">
                <p>담당자</p>
              </div>
              <div className="manager_value">
                <p>{listData.manager && listData.manager}</p>
              </div>
            </li>
            <li>
              <div className="email">
                <p>이메일</p>
              </div>
              <div className="email_value">
                <p>{listData.email && listData.email}</p>
              </div>
            </li>
            <li>
              <div className="contact">
                <p>연락처</p>
              </div>
              <div className="contact_value">
                <p>{listData.contact && listData.contact}</p>
              </div>
            </li>
            <li>
              <div className="quote_status">
                <p>상태</p>
              </div>
              <div className="quote_status_value input_radio_area">
                <span>
                  처리전
                  <input
                    name="isDone"
                    type={"radio"}
                    value={"false"}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={!contentsSend.isDone}
                  />
                </span>
                <span>
                  처리완료
                  <input
                    name="isDone"
                    type={"radio"}
                    value={"true"}
                    onChange={(e) => {
                      onChangeData(e);
                    }}
                    checked={contentsSend.isDone}
                  />
                </span>
              </div>
            </li>
            <li className="contents-css">
              <div className="contents">
                <p>내용</p>
              </div>
              <div dangerouslySetInnerHTML={{ __html: listData.content }} />
            </li>
            <div className="dot_line" />
            <li>
              <div className="memo">
                <p>메모</p>
              </div>
              <div className="memo_value input_area">
                <textarea
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                  name={"memo"}
                  defaultValue={listData.memo && listData.memo}
                  rows={5}
                />
              </div>
            </li>
          </ul>
        </BoardBoxStyle>
      )}
      {path === "notice" && (
        <BoardBoxStyle>
          <ul>
            <li>
              <div className="title">
                <p>제목</p>
              </div>
              <div className="title_value input_area">
                <input
                  onChange={(e) => {
                    onChangeData(e);
                  }}
                  name="title"
                  type={"text"}
                  defaultValue={listData.title && listData.title}
                />
              </div>
            </li>
            <li>
              <div className="post_status">
                <p>게시 상태</p>
              </div>
              <div className="post_status_value input_radio_area">
                {
                  <>
                    <span>
                      게시중
                      <input
                        name="isActive"
                        type={"radio"}
                        value={"true"}
                        onChange={(e) => {
                          onChangeData(e);
                        }}
                        checked={contentsSend.isActive}
                      />
                    </span>
                    <span>
                      게시중지
                      <input
                        name="isActive"
                        type={"radio"}
                        value={"false"}
                        onChange={(e) => {
                          onChangeData(e);
                        }}
                        checked={!contentsSend.isActive}
                      />
                    </span>
                  </>
                }
              </div>
            </li>
            <li className="contents-css">
              <div className="contents">
                <p>내용</p>
              </div>
              <Editor
                contentsSend={contentsSend}
                setContentsSend={setContentsSend}
                listData={listData}
              />
            </li>
          </ul>
        </BoardBoxStyle>
      )}
    </>
  );
};

export default Board;
