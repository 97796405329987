import axios from "axios";
// const API_URL = "";
const API_URL = process.env.REACT_APP_SERVER_URL;

const imageExtract = (inputText: string) => {
  // 정규 표현식을 사용하여 base64 이미지 문자열을 찾습니다.
  const regex = /<img src="(data:image\/[^;]+;base64,[^"]+)"/g;
  const match = regex.exec(inputText);
  // 첫 번째 매치를 변수에 저장합니다.
  const base64ImageString = match ? [match[1]] : [];
  return base64ImageString;
};

const ADMIN_MAIN_POST = ({ setDateList, data }: any) =>
  axios
    .post(`${API_URL}/board/admin/showAdminHomeTitleList`, {
      legion_id: data.legionid,
      data: {
        legion_id: data.legionid,
      },
      tempAdminPassKey: "testAdminSecret_LegionId1",
    })
    .then((res) => {
      return setDateList(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });

const COUNT_DAY = ({ setVisitList, data }: any) =>
  axios
    .post(`${API_URL}/countVisit/getAllAndTodayAll`, {
      legion_id: data.legionid,
    })
    .then((res) => {
      return setVisitList(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });

const COUNT_SEARCH = ({ start, end, setDate, data }: any) =>
  axios
    .post(`${API_URL}/countVisit/get`, {
      legion_id: data.legionid,
      from: start.replace(/\d{2}:\d{2}:\d{2}/, "00:00:00"),
      to: end.replace(/\d{2}:\d{2}:\d{2}/, "23:59:59"),
      detail: true,
    })
    .then((res) => {
      return setDate(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });

const LOGIN = ({ values, setIsPopup, isPopup, setCookie, navigate }: any) =>
  axios({
    method: "POST",
    url: `${API_URL}/login/admin`,
    data: values,
    withCredentials: true,
  }).then((res) =>
    res.data.error
      ? setIsPopup(!isPopup)
      : (setCookie("adminLoginToken", res.data.data.adminLoginToken),
        navigate("/home"))
  );
const SAVE_CONTENTS = ({
  index,
  sendPath,
  path,
  contentsSend,
  uploadedImageUrls,
}: {
  index: any;
  sendPath: any;
  path: any;
  contentsSend: any;
  uploadedImageUrls?: any;
}) =>
  index !== "write"
    ? axios
        .post(`${API_URL}/board/admin/update`, {
          table: sendPath,
          id: Number(index),
          data: {
            title: contentsSend.title,
            place: contentsSend.place,
            area: contentsSend.area,
            discription: contentsSend.discription,
            isActive: contentsSend.isActive,
            isMainpage: contentsSend.isMainpage,
            imageList: uploadedImageUrls
              ? uploadedImageUrls
              : contentsSend.imageList !== undefined &&
                contentsSend.imageList.length > 0
              ? [contentsSend.imageList[0]]
              : imageExtract(contentsSend.content),
            content: contentsSend.content,
            term: contentsSend.term,
            status_id: Number(contentsSend.status_id),
            type_id: Number(contentsSend.type_id),
            memo: contentsSend.memo,
            isDone: contentsSend.isDone,
          },
          tempAdminPassKey: "testAdminSecret_LegionId1",
        })
        .then((res) => {
          alert(res.data.message);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          throw console.log(Error);
        })
    : axios
        .post(`${API_URL}/board/admin/create`, {
          table: sendPath,
          data: {
            title: contentsSend.title,
            place: contentsSend.place,
            area: contentsSend.area,
            discription: contentsSend.discription,
            isActive: contentsSend.isActive,
            isMainpage: contentsSend.isMainpage,
            imageList: uploadedImageUrls
              ? uploadedImageUrls
              : contentsSend.imageList !== undefined &&
                contentsSend.imageList.length > 0
              ? [contentsSend.imageList[0]]
              : imageExtract(contentsSend.content),
            content: contentsSend.content,
            term: contentsSend.term,
            status_id: Number(contentsSend.status_id),
            type_id: Number(contentsSend.type_id),
            memo: contentsSend.memo,
            isDone: contentsSend.isDone,
          },
          tempAdminPassKey: "testAdminSecret_LegionId1",
        })
        .then((res) => {
          alert(res.data.message);
          window.location.href = `/${path}`;
        })
        .catch((err) => {
          console.log(err);
          throw console.log(Error);
        });
const POST_LOAD = ({ sendPath, index, setData }: any) =>
  axios
    .post(`${process.env.REACT_APP_SERVER_URL}/board/admin/post`, {
      table: sendPath,
      data: { id: Number(index) },
      tempAdminPassKey: "testAdminSecret_LegionId1",
    })
    .then((res) => {
      return setData(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });
const BOARD_REQUEST = ({
  setDataList,
  boardName,
  setPageNum,
  setLastPageNum,
  pageNum,
  data,
}: {
  setDataList: any;
  boardName: string;
  setPageNum: any;
  setLastPageNum: any;
  pageNum: number;
  data: any;
}) =>
  axios
    .post(`${process.env.REACT_APP_SERVER_URL}/board/admin/list`, {
      table: boardName,
      data: { legion_id: data.legionid },
      tempAdminPassKey: "testAdminSecret_LegionId1",
    })
    .then((res) => {
      let count = res.data.data.counts;
      let dataList = res.data.data.list;
      let page = pageNum;
      let pageCheck = (page: number) =>
        count - page * 10 > 0 ? page * 10 : count;
      let pageDate = dataList.slice(pageCheck(page), pageCheck(page + 1));
      setDataList(pageDate);
      setPageNum(page);
      setLastPageNum(Math.ceil(count / 10));
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });

const BOARD_MANAGE = ({
  deleteId,
  path,
  targetBoard,
  method,
}: {
  deleteId?: number;
  path: string;
  targetBoard?: string;
  method: string;
}) => {
  const boardMapping: { [key: string]: string } = {
    inquiry: "Board_Inquiry",
    quote: "Board_Quote",
    news: "Board_News",
    disclose: "Board_Announcement",
    notice: "Board_Notice",
    recruit: "Board_Employment",
    building: "Board_BuildingMng",
  };
  if (!targetBoard) targetBoard = boardMapping[path];
  axios
    .post(`${process.env.REACT_APP_SERVER_URL}/board/admin/${method}`, {
      table: targetBoard,
      data: {
        id: deleteId,
      },
      tempAdminPassKey: "testAdminSecret_LegionId1",
    })
    .then((res) => {
      alert(res.data.message);
      window.location.reload();
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });
};

const CREATE_BOARD = ({
  deleteId,
  path,
  targetBoard,
  method,
}: {
  deleteId?: number;
  path: string;
  targetBoard?: string;
  method: "delete" | "create";
}) => {
  const boardMapping: { [key: string]: string } = {
    inquiry: "Board_Inquiry",
    quote: "Board_Quote",
    news: "Board_News",
    disclose: "Board_Announcement",
    notice: "Board_Notice",
    recruit: "Board_Employment",
    building: "Board_BuildingMng",
  };

  if (!targetBoard) targetBoard = boardMapping[path];

  axios
    .post(`${process.env.REACT_APP_SERVER_URL}/board/admin/${method}`, {
      table: targetBoard,
      data: {
        id: deleteId,
      },
      tempAdminPassKey: "testAdminSecret_LegionId1",
    })
    .then((res) => {
      alert(res.data.message);
      window.location.reload();
    })
    .catch((err) => {
      console.log(err);
      throw console.log(Error);
    });
};
const API = {
  ADMIN_MAIN_POST,
  COUNT_DAY,
  COUNT_SEARCH,
  LOGIN,
  SAVE_CONTENTS,
  POST_LOAD,
  BOARD_REQUEST,
  BOARD_MANAGE,
  CREATE_BOARD,
};
export default API;
