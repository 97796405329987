import styled from "styled-components";
import { useLocation } from "react-router-dom";

import HeadNav from "../components/Admin/Header";
import Button from "../components/Button";
import Board from "../components/Admin/Board";
import { useEffect, useState } from "react";
import pathCheck from "../utils/pathCheck";
import AWS from "aws-sdk";
import API from "../api/api";
import { useCookies } from "react-cookie";

const saveContents = async ({
  index,
  sendPath,
  path,
  contentsSend,
  coockieData,
}: any) => {
  let uploadedImageUrls = "";
  AWS.config.update({
    region: "ap-northeast-2",
    accessKeyId: coockieData.accesstoken,
    secretAccessKey: coockieData.secretkey,
  });
  // AWS SDK 설정
  if (
    contentsSend.imageList &&
    contentsSend.imageList[0] !== undefined &&
    contentsSend.imageList[0] !== String &&
    (contentsSend.imageList[0].type === "image/png" ||
      contentsSend.imageList[0].type === "image/jpeg")
  ) {
    const s3 = new AWS.S3();
    // 썸네일 이미지 파일을 S3에 업로드하고 업로드된 이미지 URL을 가져옵니다.
    const uploadImagesToS3 = async () => {
      const files = contentsSend.imageList;
      let uploadedImageUrls: any = [];
      for (const file of files) {
        const params = {
          Bucket: "scgs-img",
          Key: file.name,
          ContentType: file.type,
          Body: file,
          ACL: "public-read",
        };
        try {
          const response = await s3.upload(params).promise();
          uploadedImageUrls.push(response.Location);
        } catch (error) {
          console.error(error);
          alert("파일 업로드 실패");
        }
      }
      return uploadedImageUrls;
    };
    // 썸네일 이미지를 S3에 업로드하고 업로드된 이미지 URL을 가져옵니다.
    uploadedImageUrls = await uploadImagesToS3();
    // 게시물 데이터를 수정하여 업로드된 이미지 URL을 사용하도록 합니다.
  }

  // input 입력 확인
  let a = Object.entries(window.document.querySelectorAll(".input_area input"));
  let c = Object.entries(window.document.querySelectorAll(".ql-blank"));

  try {
    [...a].forEach((e) => {
      if ((e[1] as HTMLInputElement).value === "") {
        throw new Error("stop loop");
      }
      if (Boolean(c[0]) === true) {
        throw new Error("stop loop");
      }
    });
  } catch (e) {
    return alert("모든 값을 입력해 주세요");
  }
  API.SAVE_CONTENTS({ index, sendPath, path, contentsSend, uploadedImageUrls });
};

const AdminBoardStyle = styled.div`
  .body {
    transition: 0.3s;
    height: calc(100vh - 50px);
    padding: 0 100px;
    .board-title {
      display: flex;
      position: relative;
      height: 30px;
      margin: 50px 0;
      .back-button {
        display: flex;
        float: left;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.3s;
        border-radius: 5px;
        padding: 4px 10px;
        &:hover {
          background: #eee;
        }
        .left-button {
          display: inline-block;
          width: 20px;
          height: 20px;
          div {
            position: relative;
            display: flex;
            p {
              position: absolute;
              transition: 0.3s;
              left: 5px;
              width: 10px;
              height: 2px;
              background: #000;
              margin: auto;
              top: 7px;
              transform: rotate(-45deg);
              &:last-child {
                top: 13px;
                transform: rotate(45deg);
              }
            }
          }
        }
      }
      h1 {
        margin: auto;
      }
    }
    .button-box {
      font-weight: 600;
      display: flex;
      margin: auto;
      width: fit-content;
      padding: 20px 0px 50px;
      & > button:last-of-type {
        margin-left: 10px;
      }
    }
  }
  @media (max-width: 768px) {
    .body {
      padding: 0 10px;
    }
  }
`;

const AdminBoard = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const index = location.pathname.split("/")[2];
  const [sendPath, setSendPath] = useState("");
  const [data, setData] = useState([]);
  const [contentsSend, setContentsSend] = useState(data);

  useEffect(() => {
    // 게시글의 저장된 값을 가져옴
    pathCheck({ path, setSendPath });
    sendPath !== "" &&
      index !== "write" &&
      API.POST_LOAD({ sendPath, index, setData });
  }, [sendPath]);
  const [cookies, setCookie, removeCookie] = useCookies(["adminLoginToken"]);
  const parts = cookies.adminLoginToken && cookies.adminLoginToken.split(".");
  const coockieData =
    cookies.adminLoginToken && JSON.parse(atob(parts[1])).data;
  return (
    <AdminBoardStyle>
      <HeadNav
        data={coockieData}
        cookies={cookies}
        removeCookie={removeCookie}
      />
      <div className="body">
        <div className="board-title">
          <div
            className="back-button no-select pointer"
            onClick={() => {
              window.history.back();
            }}
          >
            <div className="left-button">
              <div>
                <p></p>
                <p></p>
              </div>
            </div>
            <div>뒤로가기</div>
          </div>
          <h1>게시물</h1>
        </div>
        <Board
          listData={data}
          contentsSend={contentsSend}
          setContentsSend={setContentsSend}
        />
        <div className="button-box">
          <Button
            onClick={() => window.history.back()}
            cursor={"pointer"}
            w={`100px`}
          >
            취소
          </Button>
          <Button
            onClick={() =>
              saveContents({
                sendPath,
                contentsSend,
                index,
                path,
                coockieData,
              })
            }
            cursor={"pointer"}
            w={`100px`}
            buttonType={"blue"}
          >
            저장
          </Button>
        </div>
      </div>
    </AdminBoardStyle>
  );
};

export default AdminBoard;
