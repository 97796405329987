import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../Button";
import Modal from "../Modal";

import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import API from "../../api/api";

const IntroStyle = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/intro.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  position: relative;
  .form-box {
    position: absolute;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    top: 50%;
    left: 50%;
    box-shadow: 0 0 10px 0 #00000022;
    background: #ffffffee;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    max-height: 500px;
    height: 100%;
    transform: translate(-50%, -50%);
  }
  .login-box {
    display: flex;
    margin: 80px auto;
    .input-box {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      input {
        height: 30px;
        border: 1px solid #aaa;
        border-radius: 5px;
        &:last-child {
          margin-top: 10px;
        }
      }
    }
    .button-box {
      display: flex;
      height: 100%;
      position: relative;
      & > button {
        margin: auto;
        height: 100%;
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const Intro = () => {
  const [isPopup, setIsPopup] = useState(Boolean);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [values, setValues] = useState({
    account: "",
    passwd: "",
    // account: "testadminkr",
    // passwd: "testpw",
  });
  const navigate = useNavigate();

  const handleChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };
  useEffect(() => {
    cookies.adminLoginToken ? navigate("/home") : <></>;
  }, [cookies]);

  return (
    <div>
      <IntroStyle>
        <div onSubmit={handleSubmit} className="form-box">
          <h1>관리자 페이지</h1>
          <form className="login-box">
            <div className="input-box">
              <input
                placeholder="아이디"
                type="text"
                autoComplete="off"
                name="account"
                value={values.account}
                onChange={handleChange}
              />
              <input
                placeholder="비밀번호"
                type="password"
                autoComplete="new-password"
                name="passwd"
                value={values.passwd}
                onChange={handleChange}
              />
            </div>
            <div
              onClick={() =>
                API.LOGIN({ values, setIsPopup, isPopup, setCookie, navigate })
              }
              className="button-box"
            >
              <Button
                type="submit"
                cursor={"pointer"}
                buttonType="blue"
                w={"120px"}
              >
                로그인
              </Button>
            </div>
          </form>

          <div>
            <img src="/logo_sm_color.svg" alt="" srcSet="" />
          </div>
        </div>
      </IntroStyle>
      {isPopup && (
        <Modal isPopup={isPopup} type={"login"} setIsPopup={setIsPopup} />
      )}
    </div>
  );
};
export default Intro;
