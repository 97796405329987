import React from "react";
import SCGSRouter from "./Routes";
import { CookiesProvider } from "react-cookie";

const App = () => {
  return (
    <React.StrictMode>
      <CookiesProvider>
        <SCGSRouter />
      </CookiesProvider>
    </React.StrictMode>
  );
};

export default App;
