import { Link } from "react-router-dom";
import styled from "styled-components";

const WriteButtonStyle = styled.div`
  text-align: center;
  padding: 5px 15px;
  display: flex;
  width: fit-content;
  float: right;
  background: #026;
  color: #fff;
  border-radius: 5px;
  position: relative;
  p {
    display: flex;
    margin: auto 5px;
  }
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

const WriteButton = () => {
  return (
    <WriteButtonStyle className="no-select pointer">
      <p>
        <img src="./icon/edit.svg" alt="write" />
      </p>
      <p>글쓰기</p>
      <Link to={"write"}></Link>
    </WriteButtonStyle>
  );
};

export default WriteButton;
