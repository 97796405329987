import { useEffect, useMemo, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "quill/dist/quill.snow.css";
import styled from "styled-components";
import AWS from "aws-sdk";
import { useCookies } from "react-cookie";

const quillColor = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
  "unset",
  "#0000FF",
];
const quillFont = [
  "8px",
  "9px",
  "10px",
  "11px",
  "12px",
  "13px",
  "14px",
  "15px",
  "16px",
  "17px",
  "18px",
  "19px",
  "20px",
  "21px",
  "22px",
  "23px",
  "24px",
  "25px",
  "26px",
  "27px",
  "28px",
  "29px",
  "30px",
  "31px",
  "32px",
];
const formats = [
  "header",
  "font",
  "size",
  "color",
  "background",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "align",
];

var Size = Quill.import("attributors/style/size");
Size.whitelist = quillFont;
Quill.register(Size, true);

const QuillStyle = styled.div`
  .ql-container {
    min-height: 200px;
    height: 500px;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value) !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="15px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="17px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="19px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="21px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="23px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="25px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="27px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="29px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="31px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
    content: attr(data-value) !important;
    font-size: 14px !important;
  }
`;

const Editor = ({ listData, contentsSend, setContentsSend }: any) => {
  const [cookies, setCookie, removeCookie] = useCookies(["adminLoginToken"]);
  const parts = cookies.adminLoginToken && cookies.adminLoginToken.split(".");
  const coockieData =
    cookies.adminLoginToken && JSON.parse(atob(parts[1])).data;

  const quillRef = useRef<any>(null);
  const imageApi = async ({ file }: any) => {
    let uploadedImageUrls = "";
    AWS.config.update({
      region: "ap-northeast-2",
      accessKeyId: coockieData.accesstoken,
      secretAccessKey: coockieData.secretkey,
    });
    const s3 = new AWS.S3();
    const uploadImagesToS3 = async () => {
      const files = [file];
      let uploadedImageUrls: any = [];
      for (const file of files) {
        const params = {
          Bucket: "scgs-img",
          Key: file.name,
          ContentType: file.type,
          Body: file,
          ACL: "public-read",
        };
        try {
          const response = await s3.upload(params).promise();
          uploadedImageUrls.push(response.Location);
        } catch (error) {
          console.error(error);
          alert("파일 업로드 실패");
        }
      }
      return uploadedImageUrls;
    };
    return (uploadedImageUrls = await uploadImagesToS3());
  };

  // Editor 이미지 추가 기능
  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.setAttribute("multiple", "");
    input.click();
    input.addEventListener("change", async () => {
      const files = input.files;
      if (files) {
        try {
          const imageUrls = await Promise.all(
            Array.from(files).map(async (file) => {
              const res = await imageApi({ file: file });
              return res[0];
            })
          );

          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();

          imageUrls.forEach((imgUrl) => {
            editor.insertEmbed(range.index, "image", imgUrl);
            range.index += 1; // 다음 이미지를 다음 위치에 삽입
          });

          editor.setSelection(range.index);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  // Editor 기능 init
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ size: quillFont }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ color: quillColor }],
          [{ background: quillColor }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
          ["clean"],
          [{ align: ["", "center", "right", "justify"] }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const changeBase64 = (html: any) => {
    const base64Pattern =
      /<img\s+src="data:image\/([a-zA-Z]+);base64,([^"]+?)"/g;
    const matches = [...html.matchAll(base64Pattern)];
    matches.forEach(async (match, index) => {
      const mimeType = match[1];
      const base64Data = match[2];
      const base64String = `data:image/${mimeType};base64,${base64Data}`;
      if (!isValidBase64(base64Data)) {
        console.error(`Invalid base64 string at index ${index}`);
        return;
      }

      const cleanedBase64 = cleanBase64String(base64Data);
      const blob = base64ToBlob(cleanedBase64, `image/${mimeType}`);

      if (blob) {
        const name = Date().split(" ");
        const name2 = name[4].split(":");
        const fileName = `${name[3]}${name[1]}${name[2]}_${name2[0]}${
          name2[1]
        }${name2[2]}_screenshot_${index + 1}.png`;
        const file = new File([blob], fileName);

        // 파일 저장 로직 (예: 서버로 업로드 또는 로컬 파일 시스템에 저장)
        const res = await imageApi({ file: file });

        // HTML 텍스트에서 base64 이미지를 파일 경로로 치환
        return (html = html.replace(base64String, res[0]));
      }
    });
    return;
  };
  const isValidBase64 = (base64String: string) => {
    const base64Pattern = /^[a-zA-Z0-9+/]+={0,2}$/;
    return base64Pattern.test(base64String);
  };

  const cleanBase64String = (base64String: string) => {
    return base64String.replace(/[\s"']/g, "");
  };

  const base64ToBlob = (base64: string, mime: string) => {
    try {
      const byteChars = atob(base64);
      const byteNumbers = Array.from(byteChars, (char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mime });
    } catch (error) {
      console.error("Failed to convert base64 to Blob:", error);
      return null;
    }
  };

  useEffect(() => {
    setContentsSend(listData);
  }, [listData]);

  const handleChange = (html: any) => {
    changeBase64(html);
    setContentsSend({ ...contentsSend, content: html });
  };

  return (
    <QuillStyle>
      <ReactQuill
        ref={quillRef}
        onChange={(html) => handleChange(html)}
        value={contentsSend.content}
        modules={modules}
        formats={formats}
      />
    </QuillStyle>
  );
};

export default Editor;
