import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Modal from "../Modal";
import { useLocation } from "react-router-dom";
interface ListWidthInterface {
  liWidth: Array<number | "max">;
}
interface ListBoxInterface {
  liWidth: Array<number | "max">;
  val: {};
  list: {
    id?: number;
    title?: string;
    isMainpage?: boolean;
    isActive?: boolean;
    created_at?: string;
    term?: string;
    date?: string;
    status_id?: number;
    number?: string;
    area?: string;
    discription?: string;
    place?: string;
    writer?: string;
    type_id?: number;
    company?: string;
    contact?: string;
    email?: string;
    isDone?: boolean;
    manager?: string;
    productType_id?: number;
  }[];
}
const listWidthOption = {
  id: `50px`,
  title: { w: "100%", minw: "250px" },
  isMainpage: "100px",
  isActive: `100px`,
  created_at: `100px`,
  term: "200px",
  date: "150px",
  status_id: "150px",
  number: "50px",
  area: "100px",
  discription: "200px",
  place: "100px",
  writer: "100px",
  type_id: "100px",
  company: "200px",
  contact: "200px",
  email: "200px",
  isDone: "100px",
  manager: "150px",
  productType_id: "200px",
};
const UlStyle = styled.ul<ListWidthInterface>`
  width: 100%;
  overflow: overlay;
  .ul-inner-box {
    width: fit-content;
    min-width: 100%;
    min-height: calc(44px * 11);
  }
  .ul-header {
    display: flex;
    li {
      padding-bottom: 10px;
      border-bottom: 1px solid #000;
    }
  }
  .list-box {
    display: flex;
    cursor: pointer;
    position: relative;
    &:nth-of-type(2n) {
      background: #fafafa;
    }
    &:hover {
      transition: 0.1s;
      background: #eee;
    }
    li {
      padding: 10px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .delete {
      min-width: 50px;
      transition: 0.3s;
      &:hover {
        transition: 0.1s;
        background: #ddd;
      }
      &:active {
        background: #fff;
      }
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 50px);
      height: 100%;
    }
  }
  li {
    margin: auto 0;
  }
`;

const ListBox = ({ liWidth, val, list }: ListBoxInterface) => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const [isPopup, setIsPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(Number);
  return (
    <>
      <UlStyle liWidth={liWidth}>
        <div className="ul-inner-box">
          <ul className="ul-header">
            {liWidth.map((res, idx) => {
              return (
                <li
                  style={{
                    width: res !== "max" ? `${res}px` : "100%",
                    minWidth: res !== "max" ? `${res}px` : "250px",
                    maxWidth: res !== "max" ? `${res}px` : "",
                  }}
                  key={`unique0_${idx}`}
                >
                  <>{Object.values(val)[idx]}</>
                </li>
              );
            })}
          </ul>
          {list.map((res, idx1) => {
            return (
              <ul className="list-box" key={`unique_2${idx1}`}>
                {res.id !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.id}`,
                      minWidth: `${listWidthOption.id}`,
                      maxWidth: `${listWidthOption.id}`,
                    }}
                  >
                    {res.id}
                  </li>
                )}
                {res.title !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.title.w}`,
                      minWidth: `${listWidthOption.title.minw}`,
                      maxWidth: `${listWidthOption.title.w}`,
                    }}
                  >
                    {res.title}
                  </li>
                )}
                {res.isMainpage !== undefined && path === "news" && (
                  <li
                    style={{
                      width: `${listWidthOption.isMainpage}`,
                      minWidth: `${listWidthOption.isMainpage}`,
                      maxWidth: `${listWidthOption.isMainpage}`,
                    }}
                  >
                    {res.isMainpage ? "게시중" : "-"}
                  </li>
                )}
                {res.isActive !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.isActive}`,
                      minWidth: `${listWidthOption.isActive}`,
                      maxWidth: `${listWidthOption.isActive}`,
                    }}
                  >
                    {res.isActive ? "게시중" : "중지"}
                  </li>
                )}
                {res.place !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.place}`,
                      minWidth: `${listWidthOption.place}`,
                      maxWidth: `${listWidthOption.place}`,
                    }}
                  >
                    {res.place && res.place}
                  </li>
                )}
                {res.date !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.date}`,
                      minWidth: `${listWidthOption.date}`,
                      maxWidth: `${listWidthOption.date}`,
                    }}
                  >
                    {res.date}
                  </li>
                )}
                {res.writer !== undefined &&
                  window.location.pathname !== "/inquiry" && (
                    <li
                      style={{
                        width: `${listWidthOption.writer}`,
                        minWidth: `${listWidthOption.writer}`,
                        maxWidth: `${listWidthOption.writer}`,
                      }}
                    >
                      {res.writer}
                    </li>
                  )}
                {res.type_id !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.type_id}`,
                      minWidth: `${listWidthOption.type_id}`,
                      maxWidth: `${listWidthOption.type_id}`,
                    }}
                  >
                    {/* 신입 (id = 1) / 경력 (id = 2) / 신입/경력 (id = 3) / 인턴 (id = 4) */}
                    {path === "recruit" && (
                      <>
                        {res.type_id === 1 && "신입"}
                        {res.type_id === 2 && "경력"}
                        {res.type_id === 3 && "신입/경력"}
                        {res.type_id === 4 && "인턴"}
                      </>
                    )}
                    {/* 대기중 (id=1) / 처리중 (id=2) / 처리완료 (id=3) */}
                    {path === "inquiry" && (
                      <>
                        {res.status_id === 1 && "대기중"}
                        {res.status_id === 2 && "처리중"}
                        {res.status_id === 3 && "처리완료"}
                      </>
                    )}
                  </li>
                )}
                {res.status_id !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.status_id}`,
                      minWidth: `${listWidthOption.status_id}`,
                      maxWidth: `${listWidthOption.status_id}`,
                    }}
                  >
                    {/* 진행전 (id=1) / 진행중 (id=2) / 채용완료 (id=3) */}
                    {path === "recruit" && (
                      <>
                        {res.status_id === 1 && "진행전"}
                        {res.status_id === 2 && "진행중"}
                        {res.status_id === 3 && "채용완료"}
                      </>
                    )}
                    {/* 제보 (id=1) / 건의 (id=2) / 기타 (id=3) */}
                    {path === "inquiry" && (
                      <>
                        {res.type_id === 1 && "제보"}
                        {res.type_id === 2 && "건의"}
                        {res.type_id === 3 && "기타"}
                      </>
                    )}
                  </li>
                )}
                {res.term !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.term}`,
                      minWidth: `${listWidthOption.term}`,
                      maxWidth: `${listWidthOption.term}`,
                    }}
                  >
                    {res.term && res.term}
                  </li>
                )}
                {res.area !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.area}`,
                      minWidth: `${listWidthOption.area}`,
                      maxWidth: `${listWidthOption.area}`,
                    }}
                  >
                    {res.area && res.area}
                  </li>
                )}
                {res.discription !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.discription}`,
                      minWidth: `${listWidthOption.discription}`,
                      maxWidth: `${listWidthOption.discription}`,
                    }}
                  >
                    {res.discription && res.discription}
                  </li>
                )}
                {res.company !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.company}`,
                      minWidth: `${listWidthOption.company}`,
                      maxWidth: `${listWidthOption.company}`,
                    }}
                  >
                    {res.company && res.company}
                  </li>
                )}
                {res.productType_id !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.productType_id}`,
                      minWidth: `${listWidthOption.productType_id}`,
                      maxWidth: `${listWidthOption.productType_id}`,
                    }}
                  >
                    {/* 서버 / 스토리지 / DPS / 클라이언트 솔루션 / 네트워크 / 기타 등 */}
                    {res.productType_id === 1 && "서버"}
                    {res.productType_id === 2 && "스토리지"}
                    {res.productType_id === 3 && "DPS"}
                    {res.productType_id === 4 && "클라이언트 솔루션"}
                    {res.productType_id === 5 && "네트워크"}
                    {res.productType_id === 6 && "기타"}
                    {res.productType_id === 8 && "Omada"}
                    {res.productType_id === 9 && "VIGI"}
                    {res.productType_id === 10 && "전자칠판"}
                    {res.productType_id === 11 && "TAPO"}
                    {/* TODO */}
                    {res.productType_id === 12 && "미설정12"}
                    {res.productType_id === 13 && "미설정13"}
                    {res.productType_id === 14 && "미설정14"}
                    {res.productType_id === 15 && "미설정15"}
                    {res.productType_id === 16 && "미설정16"}
                    {res.productType_id === 17 && "서비스"}
                  </li>
                )}
                {res.email !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.email}`,
                      minWidth: `${listWidthOption.email}`,
                      maxWidth: `${listWidthOption.email}`,
                    }}
                  >
                    {res.email && res.email}
                  </li>
                )}
                {res.contact !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.contact}`,
                      minWidth: `${listWidthOption.contact}`,
                      maxWidth: `${listWidthOption.contact}`,
                    }}
                  >
                    {res.contact && res.contact}
                  </li>
                )}
                {res.manager !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.manager}`,
                      minWidth: `${listWidthOption.manager}`,
                      maxWidth: `${listWidthOption.manager}`,
                    }}
                  >
                    {res.manager && res.manager}
                  </li>
                )}
                {res.isDone !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.isDone}`,
                      minWidth: `${listWidthOption.isDone}`,
                      maxWidth: `${listWidthOption.isDone}`,
                    }}
                  >
                    {res.isDone ? "처리" : "처리전"}
                  </li>
                )}
                {res.created_at !== undefined && (
                  <li
                    style={{
                      width: `${listWidthOption.created_at}`,
                      minWidth: `${listWidthOption.created_at}`,
                      maxWidth: `${listWidthOption.created_at}`,
                    }}
                  >
                    {res.created_at.slice(0, 10).replace(/-/g, ".")}
                  </li>
                )}
                <li
                  className="delete no-select"
                  onClick={() => (
                    setIsPopup(true), res.id && setDeleteId(res.id)
                  )}
                >
                  <img src="./icon/trash.svg" alt="trash" />
                </li>
                <Link to={`${res.id}`}></Link>
              </ul>
            );
          })}
        </div>
      </UlStyle>
      {isPopup ? (
        <Modal
          isPopup={isPopup}
          deleteId={deleteId}
          setIsPopup={setIsPopup}
          type={"delete"}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default ListBox;
