import styled from "styled-components";

const PagingStyle = styled.ul`
  text-align: center;
  display: flex;
  width: fit-content;
  margin: auto;
  li {
    width: 22px;
    border: 1px solid #026;
    margin-right: 5px;
    cursor: pointer;
    transition: 0.3s;
    :hover {
      transition: 0.1s;
      transform: translate(0px, -2px);
      box-shadow: 0 2px 2px 0 #002266aa;
    }
    &.active {
      color: #fff;
      background: #026;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
`;

const Paging = ({
  lastPageNum,
  pageNum,
  boardName,
  setPageNum,
}: {
  lastPageNum?: number;
  pageNum?: number;
  boardName?: string;
  setPageNum?: any;
}) => {
  const mapArray = [...Array(lastPageNum)].map((x, idx) => idx + 1);
  return (
    <PagingStyle className="no-select">
      <li
        onClick={() => {
          pageNum && pageNum > 0 && setPageNum(pageNum - 1);
        }}
      >
        {"<"}
      </li>
      {mapArray.map((res, idx) => {
        return (
          <li
            onClick={() => setPageNum(idx)}
            className={idx === pageNum ? "active" : ""}
            key={idx}
          >
            {res}
          </li>
        );
      })}
      <li
        onClick={() => {
          (pageNum || pageNum === 0) &&
            lastPageNum &&
            pageNum < lastPageNum - 1 &&
            setPageNum(pageNum + 1);
        }}
      >
        {">"}
      </li>
    </PagingStyle>
  );
};

export default Paging;
