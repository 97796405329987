import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

interface dataConfig {
  setStartData?: any;
  setEndData?: any;
}
const Border = styled.div`
  width: fit-content;
  display: flex;
  .react-datepicker-wrapper {
    width: fit-content;
    span {
      display: none;
    }
  }
  .react-datepicker__tab-loop {
    position: absolute;
  }
`;
const PickerStyle = styled.div`
  &:last-of-type {
    margin-left: 10px;
  }
  .date-picker-box {
    border: 1px solid #000;
    border-radius: 5px;
    margin-top: 10px;
  }
  input {
    transition: 0.3s;
    background: none;
    padding: 10px 30px;
    width: 150px;
    text-align: center;
    text-indent: 0;
    &:focus {
      transition: 0.1s;
      box-shadow: 0 0 8px 0 #00226633;
    }
  }
`;

const DatePickerComponent = ({ setStartData, setEndData }: dataConfig) => {
  const [seletData, setSeletData] = useState<Date>(new Date());
  useEffect(() => {
    setStartData && setStartData(seletData.toISOString());
    setEndData && setEndData(seletData.toISOString());
  }, [seletData]);

  return (
    <PickerStyle>
      <div>
        <p>기간 설정</p>
      </div>
      <div className="date-picker-box">
        <DatePicker
          selected={seletData}
          onChange={(date: Date) => (
            setSeletData(date),
            setStartData && setStartData(seletData.toISOString()),
            setEndData && setEndData(seletData.toISOString())
          )}
        />
      </div>
    </PickerStyle>
  );
};

const CalendarBox = ({ setStart, setEnd }: any) => {
  return (
    <Border>
      <DatePickerComponent setStartData={setStart}></DatePickerComponent>
      <DatePickerComponent setEndData={setEnd}></DatePickerComponent>
    </Border>
  );
};

export default CalendarBox;
